var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "RefImageEdit-page" },
    [
      _c("section", [
        _c(
          "div",
          { staticClass: "container py-5" },
          [
            _c("mega-card", { staticClass: "row" }, [
              _c("div", { staticClass: "sm-down:col-12 md-up:col-6" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c(
                      "mega-drop-zone",
                      {
                        class: { "await loading": _vm.img_loading },
                        attrs: { type: ["image/jpeg", "image/png"] },
                        on: { change: _vm.renderFile }
                      },
                      [
                        _c("mega-image", {
                          staticClass: "image bg-contain",
                          style: { backgroundSize: "contain" },
                          attrs: { ratio: "16x9", src: _vm.preview }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.is_exist
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn-danger w-100 mt-2",
                            attrs: { href: _vm.existUrl, target: "_blank" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("ref_exist_btn")) +
                                " ID: " +
                                _vm._s(_vm.existId)
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "mega-button",
                      {
                        staticClass: "btn btn-light w-100 mt-2",
                        on: { click: _vm.openPreview }
                      },
                      [
                        _c("i", {
                          staticClass: "i-zoom-in",
                          style: { fontSize: "24px" }
                        })
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("mega-input", {
                      attrs: { label: _vm.$t("model_name") },
                      model: {
                        value: _vm.refImage.name,
                        callback: function($$v) {
                          _vm.$set(_vm.refImage, "name", $$v)
                        },
                        expression: "refImage.name"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-textarea", {
                      attrs: { label: _vm.$t("desc") },
                      model: {
                        value: _vm.refImage.description,
                        callback: function($$v) {
                          _vm.$set(_vm.refImage, "description", $$v)
                        },
                        expression: "refImage.description"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sm-down:col-12 md-up:col-6" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _vm.refImage.ocr_strings
                      ? _c("Dropdown", {
                          attrs: {
                            label: _vm.$t("ref_ocr"),
                            key_words: _vm.refImage.ocr_strings
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("mega-input", {
                      attrs: { label: _vm.$t("ref_key_words") },
                      model: {
                        value: _vm.ref_words,
                        callback: function($$v) {
                          _vm.ref_words = $$v
                        },
                        expression: "ref_words"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-range", {
                      staticClass: "bg-primary mb-0",
                      attrs: {
                        label: _vm.$t("ref_pic_width"),
                        min: 10,
                        max: 1000,
                        step: 5
                      },
                      model: {
                        value: _vm.refImage.width,
                        callback: function($$v) {
                          _vm.$set(_vm.refImage, "width", $$v)
                        },
                        expression: "refImage.width"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-content-between text-muted"
                      },
                      [
                        _c("small", [_vm._v("10")]),
                        _vm._v(" "),
                        _c("small", [_vm._v("1000")])
                      ]
                    ),
                    _vm._v(" "),
                    _c("mega-number", {
                      attrs: { min: 10, max: 1000, step: 5 },
                      model: {
                        value: _vm.refImage.width,
                        callback: function($$v) {
                          _vm.$set(_vm.refImage, "width", $$v)
                        },
                        expression: "refImage.width"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-switch", {
                      staticClass: "w-100 mb-3 bg-success",
                      attrs: { label: _vm.$t("ref_global_loc") },
                      model: {
                        value: _vm.refImage["is_global"],
                        callback: function($$v) {
                          _vm.$set(_vm.refImage, "is_global", $$v)
                        },
                        expression: "refImage['is_global']"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-switch", {
                      staticClass: "w-100 mb-3 bg-success",
                      attrs: { label: _vm.$t("ref_sticky") },
                      model: {
                        value: _vm.refImage["sticky"],
                        callback: function($$v) {
                          _vm.$set(_vm.refImage, "sticky", $$v)
                        },
                        expression: "refImage['sticky']"
                      }
                    }),
                    _vm._v(" "),
                    !_vm.refImage["is_global"] && _vm.refImage.lat
                      ? _c("map-widget", {
                          attrs: {
                            ratio: "16x9",
                            images: _vm.ref_images,
                            lat: _vm.refImage.lat || 0,
                            "is-global": _vm.refImage.is_global,
                            lng: _vm.refImage.lng || 0
                          },
                          on: { update: _vm.updatePosition }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "navbar" },
                    [
                      _c(
                        "mega-button",
                        {
                          staticClass: "btn-danger btn-sm mr-auto",
                          on: { click: _vm.delete_image }
                        },
                        [_vm._v(_vm._s(_vm.$t("del")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "navbar-group ml-auto" },
                        [
                          _c(
                            "mega-button",
                            {
                              staticClass: "btn-white btn-sm mr-2",
                              on: { click: _vm.close }
                            },
                            [_vm._v(_vm._s(_vm.$t("close")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "mega-button",
                            {
                              staticClass: "btn-success btn-sm",
                              attrs: {
                                disabled: !_vm.passed,
                                loading: _vm.img_loading
                              },
                              on: { click: _vm.submit }
                            },
                            [_vm._v(_vm._s(_vm.$t("model_save")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("ImagePreview", {
        ref: "preview",
        attrs: { src: _vm.preview, ratio: "1x1" }
      }),
      _vm._v(" "),
      _c("delete-confirm", {
        ref: "delete-confirm",
        attrs: {
          title: _vm.$t("ref_del"),
          text: `${_vm.$t("ref_del_confirm")}: ${_vm.refImage.name}`
        },
        on: { confirm: _vm.deleteImage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }